@import "mixins";
@import "variables";
@import "animations";
@import "grained";
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");

body {
    background-color: $yellow-background;
    margin: 0;
    font-family: brandon-grotesque, sans-serif;
}
html {
    scroll-behavior: smooth;
}
::-webkit-resizer {
    background-color: $brown-black;
}
::-webkit-scrollbar {
    width: 8px;
    background-color: $brown-black;
    height: 3px;
}
::-webkit-scrollbar-button {
    height: 3px;
    background-color: $brown-black;
}
::-webkit-scrollbar-corner {
    background-color: $yellow-background;
}
::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: $yellow-background;
    border-radius: 3px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul,
li,
button,
address,
input,
textarea {
    font-family: brandon-grotesque, sans-serif;
}

nav {
    position: fixed;
    background: $menu-black;
    z-index: 10;

    @include device(not-mobile) {
        @include flex(column, center, center, false);
        right: 0;
        top: 0;
        height: 100%;
    }

    @include device(mobile) {
        @include flex(row, center, center, false);
        left: 0;
        bottom: 0;
        width: 100vw;
        font-size: 0.8em;
    }

    .side-menu {
        margin: 0;
        list-style: none;
        padding: 0;

        @include device(not-mobile) {
            @include flex(column, center, flex-end, false);
            height: 100%;
            width: 5.6rem;
        }

        @include device(mobile) {
            @include flex(row, center, center, false);
            width: 100vw;
            height: 3.6rem;
        }

        li {
            cursor: pointer;

            &.nav-item {
                @include flex(row, center, center, false);

                @include device(not-mobile) {
                    padding: 1rem;
                    width: 3.5rem;
                }

                @include device(mobile) {
                    width: 4em;
                    height: 3em;
                }
                /*&.menu-button {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
                z-index: 10;

                @include device(handheld) {
                    margin-bottom: 1em;
                }


                svg {
                    height: 5em;
                    width: auto;

                    circle {
                        background-color: $yellow-background;
                    }
                }
            }*/
                .active-after {
                    transform: scaleX(0);
                    position: absolute;
                    left: 0;
                    font-size: 2em;
                    transition: all 0.6s cubic-bezier(0.44, 1.84, 0.54, 0.67);
                    transform-origin: left;
                }

                &.active {
                    .active-after {
                        transition-delay: 600ms;
                        transform: scaleX(1);
                    }
                }
            }

            &.nav-toggle {
                font-size: 2.5em;
                z-index: 100;
                width: auto;
            }

            a {
                color: $red;
                text-decoration: none;
            }

            .menu-text {
                @include device(mobile) {
                    display: none;
                }

                @include device(not-mobile) {
                    @include flex(row, center, center, false);
                    position: absolute;
                    color: $red;
                    text-align: right;
                    width: max-content;
                    font-size: 1em;
                    z-index: 100;
                    border: 3px solid $menu-black;
                    padding: 0 0.5em;
                    background-color: $menu-black;
                    border-radius: 2px;

                    &:after {
                        content: "";
                        position: absolute;
                        height: 4px;
                        width: 4px;
                        right: -0.8em;
                        border-radius: 0px;
                        background-color: 0 0;
                        border: 6px solid transparent;
                        border-bottom-color: transparent;
                        border-left-color: $menu-black;
                    }
                }
            }

            svg {
                stroke: inherit;

                @include device(not-mobile) {
                    height: 3em;
                    width: auto;
                }

                @include device(mobile) {
                    height: 2em;
                    width: 2em;
                }

                g,
                path,
                line,
                polyline {
                    stroke: inherit;
                    transition: stroke 0.3s ease;
                    stroke-width: 0.6px;
                }

                circle {
                    stroke-width: 0.6px;
                }
            }
        }
    }

    .main-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding: 0;

        li {
            text-transform: uppercase;
        }
    }

    .sub-menu-item {
        margin-bottom: 1rem;
        position: relative;

        @include device(not-mobile) {
            font-size: 2em;
        }

        @include device(mobile) {
            font-size: 1.8em;
        }

        a {
            text-decoration: none;
            transition: color 0.3s ease;
            color: inherit;

            &.active {
                color: inherit;
            }
        }

        &:hover {
            a {
                color: inherit;
            }
        }
    }

    .img-container {
        width: 100%;
        height: 200px;
    }

    .side-panel-backdrop {
        position: fixed;

        width: -webkit-fill-available;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 0;

        @include device(not-mobile) {
            margin-right: 5.5rem;
            top: 0;
            left: 0;
        }

        @include device(mobile) {
            margin-bottom: 3.5rem;
            height: calc(100vh - 3.5rem);
            width: 100%;
            bottom: 0;
            left: 0;
        }
    }

    .side-panel {
        @include flex(column, flex-start, center, false);
        position: absolute;
        transition: background-color 0.5s ease;
        z-index: 100;
        overflow-y: auto;

        @include device(not-mobile) {
            right: 0;
            direction: rtl;
            border-left: 3px solid $menu-black;
            height: 100vh;
        }

        @include device(mobile) {
            height: calc(100vh - 3.5rem);
            left: 0;
            width: 100vw;
            bottom: 0;
        }

        & > * {
            direction: ltr;
        }
    }
}

.sub-menu-item {
    margin-bottom: 1rem;

    a {
        text-decoration: none;
        color: inherit;
        transition: color 0.3s ease;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50%;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.3s ease-in;
            height: 3px;
            color: inherit;
            background-color: black;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50%;
            transform: scaleX(0);
            transform-origin: right;
            transition: all 0.3s ease-in;
            height: 3px;
            color: inherit;
            background-color: black;
        }

        &.active {
            color: inherit;
            font-weight: 600;
            &:after {
                bottom: -2px;
                height: 4px;
                transform: scaleX(1);
            }

            &:before {
                bottom: -2px;
                height: 4px;
                transform: scaleX(1);
            }
        }
    }

    &:hover {
        a {
            color: inherit;

            &:after {
                transform: scaleX(1);
            }

            &:before {
                transform: scaleX(1);
            }
        }
    }
}

.menu-button {
    height: 1em;
    width: 1em;
    padding: 0.4em;
    border: 5px solid $menu-black;
    border-radius: 50%;
    background-color: $red;
    transition: all 0.3s ease;

    @include flex(column, center, center, false);

    @include device(not-mobile) {
        margin-right: 0.8rem;
    }

    @include device(mobile) {
        margin-bottom: 0.8em;
    }

    .menu-line {
        height: 0.1em;
        width: 1em;
        margin-bottom: 0.25em;
        background-color: $menu-black;
        transition: all 0.6s ease;
        transform-origin: center;

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(0n + 2) {
            width: 1.2em;
        }
    }

    &:hover {
        .menu-line {
            &:nth-child(0n + 2) {
                width: 0.8em;
            }
        }
    }

    &.open {
        .menu-line {
            position: absolute;
            margin-bottom: 0;

            &:last-child {
                transform: rotate(45deg);
            }

            &:first-child {
                transform: rotate(-45deg);
            }

            &:nth-child(0n + 2) {
                width: 0;
            }
        }

        transition: transform 0.3s ease;

        &:hover {
            transform: rotate(900deg);
        }
    }
}

.main {
    //margin-right: 4rem;
    @include device(mobile) {
        overflow: hidden;
    }
}

picture {
    display: grid;
}
.content {
    @include device(not-mobile) {
        margin-right: 5.5rem;
        width: calc(100vw - 5.5rem);
    }

    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s ease;

    .content-left {
        //min-height: 80vh;
        width: calc(100vw - 20.6rem);
        align-self: flex-start;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.article {
    max-width: 800px;

    @include device(desktop) {
        font-size: 1.2em;
    }

    @include device(handheld) {
        padding: 0 1em;
        font-size: 1em;
    }

    &.stories {
        max-width: 100%;
    }

    &.news {
        margin-top: 2rem;
        @include device(handheld) {
            margin: 2rem auto;
            max-width: 85%;
        }
        .highlighted-text {
            &::before {
                background-color: darken($purple-background, 10%);
            }
        }

        .boxed-text {
            background-color: lighten($purple-background, 10%);
        }

        .cta-button {
            //background-color: #9280d0;
        }
    }

    &.event-article {
        margin-top: 2rem;
        @include device(handheld) {
            margin: 2rem auto;
            max-width: 85%;
        }
        .highlighted-text {
            &::before {
                background-color: darken($green-background, 10%);
            }
        }

        .boxed-text {
            background-color: lighten($green-background, 10%);
        }

        .cta-button {
            //background-color: #9280d0;
        }
    }
}

.dynamic-content {
    p {
        img {
            width: 100%;
        }
    }
}

.overlay {
    &.after {
        content: "";
        background-color: rgba(0, 0, 0, 0.7);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }
}

.page-intro {
    padding: 0;
    margin: 0;
}

.site-logo {
    text-align: center;
    color: $red-text;
    text-decoration: none;

    h1 {
        font-size: 28px;

        @include device(desktop) {
            margin-bottom: 8rem;
        }

        @include device(desktop) {
            margin-bottom: 3rem;
        }
    }
}

.site-intro {
    background-color: $brown-black;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 4em;
    transition: background-image 0.3s ease;

    @include flex(column, flex-start, center, false);

    @include device(desktop) {
        //font-size: 176px;
        height: 680px;
        padding: 6rem 0;

        .container {
            max-width: 90vw;
            width: min-content;
        }
    }

    @include device(handheld) {
        //font-size: 80px;
        height: 66vw;
        padding: 2rem 0;

        .container {
        }
    }

    .container {
        position: fixed;
    }

    h1 {
        top: calc(50% - 0.5em);
        line-height: 1em;
        margin: 0;
        font-size: 1em;
        color: $light-red;
        font-weight: 800;
        text-align: center;

        @include device(desktop) {
            width: max-content;
        }
        //text-shadow: -9px 24px rgba(0, 0, 0, 0.1);
        span {
            display: none;
            /*position: absolute;
            color: rgba(0, 0, 0, 0.1);
            z-index: -1;
            transform: skewX(-36deg) rotateX(73deg) scaleY(2) translate(42px, 42px);
            left: 0;
            top: 0;*/
            opacity: 0;
            transition: opacity 0.3s ease;
            //transition-delay: 4s;
        }

        &.shadow {
            span {
                position: absolute;
                display: inline;
                color: rgba(0, 0, 0, 0.1);
                opacity: 1;
                z-index: -1;
                transform: skewX(-36deg) rotateX(73deg) scaleY(2) translate(42px, 42px);
                left: 0;
                top: 0;
            }
        }
    }

    h2 {
        top: calc(50% - 0.5em);
        line-height: 1em;
        margin: 0;
        font-size: 0.8em;
        color: $light-red;
        font-weight: bold;
        text-align: center;
        width: max-content;
    }

    .intro-text {
        color: $light-red;
        font-size: 0.2em;
        line-height: 1.2em;
        margin-left: 0.5em;
        position: absolute;
        font-weight: 600;
    }

    .text-box {
        width: 50%;
    }

    &.home {
        @include device(desktop) {
            min-height: 600px;
        }

        h1 {
            margin: 1rem 0;
        }

        .home-icons {
            font-size: 16em;
            //height: 260px;
            svg {
                stroke: $light-red;
            }
            path,
            polygon {
                stroke-width: inherit;
                stroke: inherit;
            }
        }

        .icons-top {
            @include flex(row, flex-start, flex-end, false);
            transform: translateX(-150px);
        }

        .icons-bottom {
            @include flex(row, flex-end, flex-start, false);
            transform: translateX(150px);
        }

        svg {
            //margin-right: 1rem;
            max-width: 250px;
            //height: 260px;
            path,
            polygon {
                stroke-width: inherit;
            }
        }

        .arkivet-logo {
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translateX(-50%);
            width: 176px;
            height: 57px;
        }
    }
}

.page-heading-container {
    position: relative;
    //height: 100px;
    width: 100%;
    margin-bottom: 6rem;
    @include device(mobile) {
        margin-bottom: 2rem;
    }

    .page-heading {
        text-align: center;
        color: $menu-black;
        letter-spacing: 5px;
        margin-top: 2rem;
        color: $dark-text;
        letter-spacing: 5px;

        @include device(desktop) {
            font-size: 3em;
        }

        @include device(handheld) {
            font-size: 25px;
        }
    }
}

.page-content {
    font-size: 1.2em;

    .section-heading {
        margin-bottom: 1em;

        a {
            text-decoration: none;
            color: inherit;
        }
    }
}

.cta-button {
    text-decoration: none;
    margin: 1em 0;
    text-align: center;
    padding: 1em;
    border-radius: 8px;
    color: black;
    text-decoration: underline;
}

.home-content {
    @include device(not-mobile) {
        // width: calc(100vw - 5.6rem);
        // margin-right: 5.6rem;
        width: 100%;

        section {
        }
    }

    @include device(mobile) {
        width: 100%;
    }
}

.home-video-stories {
    background-color: $pink-background;
    color: $dark-text;
    overflow: hidden;
    position: relative;
    .container {
        grid-row-gap: 0;
        grid-column-gap: 2rem;
        width: 100%;
        display: flex;
        padding-top: 7rem;
        padding-bottom: 14rem;
        align-items: flex-start;
        scroll-behavior: smooth;

        overflow-x: scroll;
        overflow-y: hidden;
        @include device(desktop) {
            max-width: 100%;
            margin-left: 0vw;
        }

        @include device(desktop) {
            height: calc(3.6 * 13vw);
            height: auto;
        }

        @include device(tablet) {
            height: calc(3.6 * 21vw);
            height: auto;
        }

        @include device(mobile) {
            height: calc(3.6 * 32vw);
            height: auto;

            padding-top: 3rem;
            padding-bottom: 18rem;
            font-size: 0.8em;
        }

        .video-story {
            display: block;
            position: relative;
            padding-left: 4rem;

            &:last-child {
                padding-right: 4rem;
            }
            .video-container {
                display: flex;
                justify-content: center;
                .quote-background {
                    width: auto;

                    @include device(desktop) {
                        // top:  calc(-3.6 * 2vw);
                        // top: -5.5rem;

                        // height: 120%;
                        top: -25%;
                        height: 150%;
                    }

                    @include device(tablet) {
                        // height: calc(3.6 * 16vw);
                        // top: -4rem;

                        // height: 120%;
                        top: -25%;
                        height: 150%;
                    }

                    @include device(mobile) {
                        // top:  calc(-3.6 * 2vw);
                        // top: -3rem;

                        // height: calc(3.6 * 15vw);
                        // height: 120%;

                        top: -25%;
                        height: 150%;
                    }
                }
                .video {
                    position: relative;
                    iframe {
                        @include device(desktop) {
                            width: calc(6.4 * 5vw);
                            height: calc(3.6 * 5vw);
                        }

                        @include device(tablet) {
                            width: calc(6.4 * 10vw);
                            height: calc(3.6 * 10vw);
                        }

                        @include device(mobile) {
                            width: calc(6.4 * 10vw);
                            height: calc(3.6 * 10vw);
                        }

                        transition: all 0.3s ease;
                    }

                    &:hover {
                        iframe {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .video-content {
                padding-left: 1rem;
                transform: scale(1);
                position: absolute;
                h3 {
                    font-size: 2em;
                    margin: 0.5rem 0;
                }

                p {
                    font-size: 1.6em;
                    margin: 0;
                }
            }
            &.playing {
                iframe {
                    transform: scale(1.1);
                }
            }
        }
    }
}
.video-stories-page {
    background-color: $pink-background;
    color: $dark-text;

    .video-stories-content {
        padding: 0;
        @include device(tablet) {
            margin: 0 3rem;
        }
    }
    .video-stories-container {
        display: grid;
        margin-top: 15rem;
        margin-bottom: 10rem;
        grid-row-gap: 10rem;
    }

    .video-story {
        display: grid;

        position: relative;
        grid-gap: 4rem;
        @include device(desktop) {
            &:nth-child(2n - 1) {
                transform: translateX(12vw);
                grid-template-columns: 1fr 40vw;

                .video-container {
                    order: 2;
                }
            }
            &:nth-child(2n) {
                transform: translateX(-12vw);
                grid-template-columns: 40vw 1fr;
            }
            iframe {
                width: 40vw;
                height: calc((40vw / 640) * 360);
            }
        }

        @include device(tablet) {
            grid-gap: 2rem;
            max-width: 90vw;
            &:nth-child(2n - 1) {
                grid-template-columns: 1fr 40vw;

                .video-container {
                    order: 2;
                }
            }
            &:nth-child(2n) {
                grid-template-columns: 40vw 1fr;
            }
            iframe {
                width: 40vw;
                height: calc((40vw / 640) * 360);
            }
        }

        .video-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .video-content {
            @include device(desktop) {
                max-width: 24vw;
            }
            h3 {
                font-size: 2.5em;
                margin: 0;
            }
            p {
                margin: 0;

                font-size: 2em;
                margin-top: 1em;
            }
        }
        .video {
            transform: scale(1);
        }
        @include device(desktop) {
            .quote-background {
                position: absolute;
                height: 150%;
                width: auto;
            }
        }
    }

    .slider {
        min-height: calc(((90vw / 640) * 360) + 5rem);
        height: calc(((90vw / 640) * 360) + 20rem);
        margin-top: 6rem;
        .slides-container {
            min-height: calc(((90vw / 640) * 360) + 5rem);
            height: calc(((90vw / 640) * 360) + 10rem);
        }
        .video-story {
            display: grid;
            position: relative;
            grid-template-columns: 1fr;
            grid-gap: 0.5rem;

            h3 {
                font-size: 1.5em;
            }
            p {
                font-size: 1.2em;
            }
            iframe {
                width: 90vw;
                height: calc((90vw / 640) * 360);
            }
            .video-content {
                transform: scale(1);
            }
        }

        .quote-background {
            width: auto;
            height: 150%;
            top: -25%;
        }
        .slider-footer-controls {
            width: 56vw;
        }

        .slider-dot {
            margin: 1em auto !important;
            &.active {
                background-color: $red-text;
            }
        }
    }
}
.video-page {
    background-color: $pink-background;
    color: $dark-text;

    .article {
        max-width: 1200px;
        margin-bottom: 8rem;

        @include device(mobile) {
            max-width: 90vw;
        }
        iframe {
            min-height: calc(((90vw / 640) * 360) + 5rem);
            height: calc(((90vw / 640) * 360) + 10rem);
            width: 80vw;
        }

        .video {
            display: flex;
            justify-content: center;
        }
    }
    .video-stories-content {
        padding: 0;
        @include device(tablet) {
            margin: 0 3rem;
        }
    }
    .video-stories-container {
        display: grid;
        margin-top: 15rem;
        margin-bottom: 10rem;
        grid-row-gap: 10rem;
        width: 90vw;
    }

    // .slider {
    //     width: 1200px;
    //     height: 600px;
    //     iframe {
    //         width: 640px;
    //         height: 420px;
    //     }
    // }

    .slider {
        height: calc(((70vw / 640) * 360) + 12rem);
        margin-top: 6rem;
        @include device(mobile) {
            min-height: calc(((70vw / 640) * 360) + 5rem);
            height: calc(((70vw / 640) * 360) + 14rem);
            width: 80vw;
        }
        @include device(tablet) {
            width: 80vw;
            height: auto;
        }
        @include device(desktop) {
            width: 80vw;
            height: 420px;
        }
        .slides-container {
            min-height: calc(((70vw / 640) * 360) + 5rem);
            height: calc(((70vw / 640) * 360) + 10rem);
            @include device(desktop) {
                width: 640px;
                height: 420px;
            }
        }
        .video-story {
            display: grid;
            position: relative;
            grid-template-columns: 1fr;
            grid-gap: 0.5rem;
            .video {
                display: flex;
                justify-content: center;
            }
            h3 {
                font-size: 1.5em;
            }
            p {
                font-size: 1.2em;
            }
            iframe {
                width: 70vw;
                height: calc((70vw / 640) * 360);
                @include device(desktop) {
                    width: 640px;
                    height: 420px;
                }
            }
            .video-content {
                transform: scale(1);
            }
        }
    }
}
.video-story {
    a {
        color: inherit;
    }
   
    .quote-background {
        transition: all 0.3s ease;
        @include device(mobile) {
            height: 75%;
        }
    }

    @include device(not-mobile) {
        &:hover {
            .video {
                //transform: translateX(15px);
            }

            .quote-background {
                //transform: scale(1.2);
            }
        }

        &:nth-child(2n) {
            .video-content {
                //align-items: flex-end;
            }
            .video-container {
                //order: 1;
            }

            &:hover {
                .video {
                    //transform: translateX(15px);
                }
            }
        }
    }
}

.home-stories {
    min-height: 600px;
    background-color: $dark-brown;

    @include device(tablet) {
        .prev-slide,
        .next-slide {
            top: unset !important;
            bottom: 2rem;
        }
    }
    .section-heading {
        color: $light-text;
    }
}

.home-news {
    min-height: 600px;
    background-color: $purple-background;
}

.home-calender {
    min-height: 600px;
    background-color: $green-background;
}

section {
    margin: 0;
    position: relative;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.section-heading {
    text-align: center;
    color: $menu-black;
    letter-spacing: 5px;
    text-decoration: none;
    z-index: 9;
    font-weight: bold;
    position: relative;
    @include device(desktop) {
        margin-bottom: 4rem;
    }

    @include device(handheld) {
        margin-bottom: 2rem;
    }

    h1,
    h2 {
        font-size: 30px;
        margin-top: 0;
    }

    i {
        font-size: 20px;
    }
    a {
        text-decoration: none;
        color: inherit;
    }

    h2 {
        color: $dark-text;
        letter-spacing: 5px;
    }

    i {
        position: absolute;
        top: -2em;
        font-family: "Reenie Beanie", cursive;
        color: white;
    }
}

.container {
    position: relative;
    align-self: center;

    @include device(desktop) {
        //width: 80vw;
        max-width: 1000px;
    }
}

.card {
    .card-image-container {
        width: 100%;
        height: 350px;
        overflow: hidden;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }
}

.story-card-horizontal {
    display: grid;
    grid-column-gap: 2rem;
    margin-bottom: 2rem;
    text-decoration: none;
    color: $light-text;
    font-weight: bold;

    .foteller-name {
        font-family: "Reenie Beanie", cursive;
        font-size: 1.8em;

        @include device(not-mobile) {
            transform: translateX(-1em);
        }
    }

    .card-image-container {
        height: 350px;
        width: 100%;
        transition: all 0.3s ease;
    }

    &.right {
        grid-template-columns: 1fr 50%;
        font-size: 20px;

        @include device(mobile) {
            font-size: 14px;
            grid-template-columns: 1fr;
        }

        .card-image-container {
            order: 1;
        }
    }

    &.left {
        grid-template-columns: 50% 1fr;
        font-size: 20px;

        @include device(mobile) {
            font-size: 14px;
            grid-template-columns: 1fr;
        }

        .story-image-container {
            justify-self: center;
        }
    }

    &:hover {
        .card-image-container {
            transform: scale(1.2);
        }

        svg {
            transform: translateX(15px);
        }
    }

    .story-link {
        padding: 0.5rem 0 0.5rem 1em;
        color: $light-red;
        border-bottom: 5px solid $light-red;
        width: fit-content;
        margin-top: 2em;
        p {
            margin: 0;
        }
        svg {
            margin-left: 1em;
            transition: all 0.3s ease;
            transition-delay: 0.3s;
        }
    }
}

.home-stories {
    padding: 2rem;
    @include flex(column, flex-start, center, false);
    overflow: hidden;

    .section-heading {
    }

    .story-card-horizontal {
        @include device(mobile) {
            .card-image-container {
                height: 90vw !important;
            }
            grid-template-columns: 1fr;
            &:nth-child(2n - 1) {
                .story-info {
                    order: 1;
                }
                .quote-background {
                    bottom: 0;
                    top: unset !important;
                }
            }
        }
    }
}

.article-link {
    padding: 0.5rem 2em 0.5rem 1em;
    color: inherit;
    border-bottom: 5px solid;
    width: fit-content;
    margin-top: 2em;
    font-weight: bold;
    font-size: 1.2em;
    p {
        margin: 0;
    }
    svg {
        margin-left: 1em;
        transition: all 0.3s ease;
        transition-delay: 0.3s;
    }
}

.side-panel {
    .section-heading {
        font-size: 1.2em;
    }
    .stories {
        margin: 2em;

        width: 274px;
        .video-story {
            display: block;
            margin-bottom: 0;
            .video {
                position: relative;
            }

            .video-content {
                padding: 0;
                min-height: unset;
            }

            iframe {
                width: 100%;
                height: auto;
                    @include device(not-mobile) {
                        width: 400px;
                        height: 224px;
                    }
                
            }
        }
    }

    .exhibitions {
        margin: 2em;
        width: 274px;
        .exhibitions-container {
            display: grid;
            gap: 2rem;
        }

        a {
            text-decoration: none;
            color: $dark-text;
            position: relative;
            img {
                max-width: 100%;
            }
        }
    }

    .story-filters {
        color: $light-text;

        .section-heading {
            color: $light-text;
        }

        .sub-menu-item {
            a {
                &::before,
                &::after {
                    background-color: $light-text !important;
                }
            }
        }
    }

    .story-card-horizontal {
        direction: ltr;
        grid-column-gap: 1rem;
        transform: scale(1);
        transition: transform 0.3s ease;

        @include device(desktop) {
            grid-template-columns: 140px 1fr;
        }
        @include device(tablet) {
            font-size: 14px;

            .text-box {
                font-size: 22px;
            }
        }

        @include device(handheld) {
            grid-template-columns: 1fr;
            grid-row-gap: 1em;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .story-image-container {
        height: 200px;
        width: 100%;
        grid-row: span 2;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }

    .story-info {
        font-size: 15px;
    }
}

.event-card-bordered {
    position: relative;
    @include flex(column, flex-start, flex-start, false);
    padding-left: 60px;
    text-decoration: none;
    color: $menu-black;
    cursor: pointer;

    .article-date {
        position: absolute;
        top: 0;
        left: 0;
        @include flex(column, flex-start, center, false);
        color: $red;
        font-weight: bold;
        font-size: 32px;
        line-height: 1em;

        .divider {
            border-top: 3px solid $red;
            width: 1em;
            margin: 0.25em 0;
        }
    }

    h1,
    h2,
    h3 {
        //font-size: 18px;
        margin: 0;
        margin-bottom: 0.5rem;
        text-decoration: none;
        color: $menu-black;
    }

    .readable-date {
        font-size: 1.5em;
    }

    .img-container {
        position: relative;
        @include flex(column, center, center, false);
        width: 100%;
        margin-bottom: 1em;
        picture {
            display: grid;
            width: calc(100% - 32px);
            margin-bottom: calc(1em + 13px);
            box-shadow: 1px 1px 0px 30px $red;
            transition: all 0.3s ease;
            img {
                max-width: 100%;
                height: auto;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    &:hover {
        .img-container {
            picture {
                box-shadow: 0px 0px 0px 0px $red;
            }
        }
    }
}

.event-card {
    position: relative;
    @include flex(column, flex-start, flex-start, false);
    padding-left: 50px;
    text-decoration: none;
    color: $menu-black;
    @include device(mobile) {
        padding-right: 50px;
    }

    img {
        max-width: 100%;
    }

    cursor: pointer;

    .article-date {
        position: absolute;
        bottom: -6px;
        left: 0;
        transform: translateX(-50%);
        background-color: darken($green-background, 7%);
        @include flex(column, flex-start, center, false);
        color: $red;
        font-weight: bold;
        font-size: 32px;
        line-height: 1em;
        padding: 0.25em;

        .divider {
            border-top: 3px solid $red;
            width: 1em;
            margin: 0.25em 0;
        }
    }

    h1,
    h2,
    h3 {
        font-size: 1.2em;
        margin: 1em 0;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        color: $menu-black;
        border-bottom: 4px solid $menu-black;
    }

    .readable-date {
        font-size: 1.5em;
    }

    .image-date-container {
        position: relative;
        @include flex(column, center, center, false);
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 0px 12px darken($pink-background, 20%);
        transition: all 0.3s ease;
    }

    .img-container {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &:hover {
        .img-container {
            picture {
            }
        }

        .image-date-container {
            box-shadow: 0px 0px 0px 0px darken($pink-background, 20%);
        }
    }
}

.article-card {
    position: relative;
    @include flex(column, flex-start, flex-start, false);
    text-decoration: none;
    color: $menu-black;
    width: 100%;
    cursor: pointer;

    h1,
    h2,
    h3 {
        font-size: 1.2em;
        margin: 1em 0;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        color: $menu-black;
        border-bottom: 4px solid $menu-black;
    }

    .readable-date {
        font-size: 1.5em;
    }

    .image-container {
        position: relative;
        @include flex(column, center, center, false);
        width: 100%;
        height: 300px;
        box-shadow: 0px 0px 0px 6px darken($green-background, 20%);
    }

    .img-container {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &:hover {
        .img-container {
            picture {
            }
        }
    }
}

.dated-article-card {
    position: relative;
    @include flex(column, flex-start, flex-start, false);
    text-decoration: none;
    color: $menu-black;
    width: 90%;
    justify-self: center;

    @include device(tablet) {
        width: 85%;
    }

    cursor: pointer;

    .article-date {
        position: absolute;
        bottom: -6px;
        left: 0;
        transform: translateX(-50%);
        background-color: darken($green-background, 7%);
        @include flex(column, flex-start, center, false);
        color: $red;
        font-weight: bold;
        font-size: 32px;
        line-height: 1em;
        padding: 0.25em;

        .divider {
            border-top: 3px solid $red;
            width: 1em;
            margin: 0.25em 0;
        }
    }

    h1,
    h2,
    h3 {
        font-size: 1.2em;
        margin: 1em 0;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        color: $menu-black;
        border-bottom: 4px solid $menu-black;
    }

    .readable-date {
        font-size: 1.5em;
    }

    .image-date-container {
        position: relative;
        @include flex(column, center, center, false);
        width: 100%;
        height: 300px;
        box-shadow: 0px 0px 0px 6px darken($green-background, 20%);
    }

    .img-container {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &:hover {
        .img-container {
            picture {
            }
        }

        .article-date {
            z-index: 100;
            span {
                transition: all 0.6s ease;
                transform: rotateX(360deg);

                &:nth-child(1) {
                }

                &:nth-child(2) {
                    transition-delay: 0.3s;
                }
                &:nth-child(3) {
                    transform: unset;
                }
                &:nth-child(4) {
                    transition-delay: 0.9s;
                }
                &:nth-child(5) {
                    transition-delay: 1.2s;
                }
            }
        }
    }
}

.home-calender {
    @include flex(column, flex-start, center, false);
    padding-bottom: 7rem;
}

.events-container {
    display: grid;
    grid-column-gap: 8rem;
    grid-row-gap: 2rem;
    margin: 0 2em;
    max-width: 1400px;

    @include device(desktop) {
        grid-template-columns: 1fr 1fr;
    }

    @include device(handheld) {
        grid-template-columns: 1fr;
    }
    @include device(tablet) {
        max-width: 80vw;
        margin-top: 2rem;
    }

    .event-card-bordered {
        .event-info {
            text-align: center;
            z-index: 1;

            p {
                font-size: 24px;
            }
        }

        &:nth-child(2n) {
            transform: translateY(4rem);
        }

        @include device(not-mobile) {
            &:before {
                content: "";
                position: absolute;
                width: 300px;
                height: 450px;
                background-color: lighten($green-background, 15%);
                opacity: 0.5;
                transform: translate(-20%, 80%);
            }
        }

        &:nth-child(2n) {
            &:before {
                transform: translate(80%, -40%) rotate(90deg);
            }
        }
        :hover {
            .article-date {
                z-index: 100;
                span {
                    transition: all 0.6s ease;
                    transform: rotateX(360deg);

                    &:nth-child(1) {
                    }

                    &:nth-child(2) {
                        transition-delay: 0.3s;
                    }
                    &:nth-child(3) {
                        transform: unset;
                    }
                    &:nth-child(4) {
                        transition-delay: 0.9s;
                    }
                    &:nth-child(5) {
                        transition-delay: 1.2s;
                    }
                }
            }
        }
    }
}

.events-list {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;

    @include device(desktop) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include device(tablet) {
        width: 80%;
        margin: 0 auto;
    }

    @include device(handheld) {
        grid-template-columns: 1fr;
    }
}

.more-articles {
    width: 100%;
    background-color: $light-purple;
    .slider {
        width: 100%;
    }
    .slides-container {
        width: 100%;
    }

    .slider {
        @include device(handheld) {
            max-width: 85%;
            margin: 0 auto;
            height: 700px;
            .slider-side-controls {
                div {
                    font-size: 2em;
                }
            }
            .news-card-box {
                grid-template-columns: 1fr;
                .img-container {
                    img {
                        position: relative;
                        width: 50%;
                        transform: rotate(-15deg);
                    }
                }
            }
        }
    }
}

.other-events {
    width: 100%;
    background-color: $green-background;
    display: flex;
    flex-direction: column;
    align-items: center;

    .events-container {
        grid-column-gap: 4rem;

        @include device(desktop) {
            grid-template-columns: repeat(3, auto);
        }

        @include device(handheld) {
            grid-template-columns: 1fr;
        }

        .event-card-bordered {
            .img-container {
                @include device(desktop) {
                    margin-bottom: 2rem;

                    img {
                        height: 500px;
                        width: auto;
                    }
                }
            }

            &:nth-child(2n) {
                transform: translateY(0);
            }
        }
    }

    .event-card {
        max-width: 400px;
    }

    .slider {
        position: relative;
        min-height: 600px;
    }
}

.events-slider {
    .event-card-info {
        h2 {
            @include device(mobile) {
                font-size: 1.4em !important;
            }
        }
    }
}

.news-page {
    background-color: $light-purple;

    .page-menu {
        background-color: $dark-purple;
        color: $dark-text;

        .sub-menu-item {
            a {
                &::before {
                    background-color: $dark-text;
                }
                &:after {
                    background-color: $dark-text;
                }
            }
        }
    }

    .news-content {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 100vh;
    }

    .news-container {
        grid-column-gap: 5rem;
        grid-template-columns: repeat(3, 1fr);

        @include device(tablet) {
            grid-template-columns: 1fr;
        }
        @include device(mobile) {
            grid-template-columns: 1fr;
        }
        .dated-article-card {
            align-items: center;
            padding-top: 1em;
            padding-bottom: 1em;
        }
        .article-date {
            background-color: darken($purple-background, 15%);
            color: $light-text;
            .divider {
                border-top-color: $light-text;
            }
        }

        .article-info {
        }

        .image-date-container {
            //box-shadow: 0px 0px 0px 6px darken($purple-border, 20%);
            box-shadow: none;

            .img-container {
            }
        }

        .readable-date {
            font-size: 1.2em;
        }
    }
}

.calender-page {
    .page-menu {
        background-color: darken($green-background, 10%);
        color: inherit;
        width: 100%;
        .sub-menu-item {
            a {
                &::before {
                    background-color: inherit;
                }
                &:after {
                    background-color: inherit;
                }
            }
        }
    }

    .calender-content {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 100vh;
    }
}

.calender-panel {
    margin-bottom: 4rem;

    .events {
        @include device(not-mobile) {
            margin: 2em;
        }
    }

    .section-heading {
        @include device(mobile) {
            margin-top: 2rem;
        }
    }

    .events-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        margin: 0 2rem;
        @include device(mobile) {
            margin: 0;
        }
    }

    .event-card-bordered {
        .event-info {
            text-align: center;
            direction: ltr;
            z-index: 1;
        }

        .img-container {
            width: 100%;
        }

        h2 {
            font-size: 1.2em;
        }

        h3,
        p,
        .readable-date {
            display: none;
        }
    }
}

footer {
    background-color: $menu-black;
    padding-bottom: 4rem;
    position: relative;

    @include device(handheld) {
        font-size: 18px;
    }

    .container {
        @include device(desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .left {
        @include flex(column, center, center, false);
        a {
            @include flex(column, center, center, false);
        }
        @include device(desktop) {
            border-right: 2px solid $yellow-background;
        }

        img {
            margin-bottom: 2rem;
        }
    }

    h3 {
        color: $red;
        margin: 3rem;
        font-size: 1em;
    }

    @include flex(column, flex-start, center, false);

    .social-links {
        @include flex(row, center, center, true);

        @include device(desktop) {
        }

        @include device(handheld) {
            margin-bottom: 2em;
            margin-top: 2em;
        }

        a {
            @include flex(row, center, center, false);
            margin: 0 1rem;
            font-size: 3em;
            color: $yellow-background;
            @include device(mobile) {
                margin-bottom: 2rem;
            }

            &:hover {
                color: $red;
            }
        }
    }

    address {
        color: $yellow-background;
        font-weight: bold;
        text-align: center;
        @include device(mobile) {
            margin-bottom: 2rem;
        }
    }

    img {
        max-width: 80%;
        margin-bottom: 3rem;
    }
}
.news-slider {
    .slides-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider-dot {
        border-color: darken($purple-background, 25%);
        &.active {
            background-color: darken($purple-background, 15%);
        }
    }
}

.news-card-box {
    border: 2px solid darken($purple-background, 15%);
    background-color: lighten($purple-background, 10%);
    display: grid;
    grid-template-columns: 200px 1fr;
    min-height: 400px;
    width: 750px;
    text-decoration: none;

    .img-container {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        position: absolute;
        overflow: hidden;
        height: auto;
        width: 100%;
        transform: rotate(-20deg) translateX(-25%) translateY(-15%);
        box-shadow: -35px 35px 5px 5px rgba(0, 0, 0, 0.1);

        &.no-shadow {
            box-shadow: none;
        }
    }

    .news-card-info {
        color: #11004b;
        text-decoration: none;
        padding: 0 2em;

        h2 {
            font-size: 2em;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
}

.news-card {
    text-decoration: none;
    display: grid;
    grid-gap: 2rem;

    @include device(desktop) {
        grid-template-columns: repeat(2, auto);
    }

    @include device(handheld) {
        grid-template-columns: 1fr;
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        /*height: 400px;
            width: 20vw;*/
        img {
            width: 100%;
            height: auto;
            max-height: 300px;
            max-width: 200px;
        }
        picture {
            transition: all 0.3s ease;
        }
    }

    .article-date {
        position: relative;
        padding-left: calc(15px + 0.5em);
        display: flex;
        align-items: center;

        &:before {
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid black;
            cursor: pointer;
            position: absolute;
            left: 0;

            &.active {
                background-color: $dark-text;
            }
        }
    }

    .news-card-info {
        color: $dark-text;
    }

    .read-more {
        display: block;
        position: relative;
        opacity: 1;
        //transition: all .3s ease;
        //transform: translateX(00px);
    }

    &:nth-child(2n-1) {
        .img-container {
            // order: 1;
            img {
                // transform: rotate(25deg);
            }
        }
    }

    &:nth-child(2n) {
        .img-container {
            //order: 0;
            img {
                // transform: rotate(-25deg);
            }
        }
    }

    &:hover {
        .img-container {
            //order: 0;
            picture {
                transform: translateX(-50px);
                // transform: rotate(-25deg);
            }
        }

        .article-date {
            &:before {
                background-color: $dark-text;
            }
        }
    }
}

.news-card-small {
    text-decoration: none;
    color: inherit;

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        /*height: 400px;
            width: 20vw;*/
        img {
            width: 100%;
            height: auto;
            max-height: 300px;
            transition: all 0.3s ease;
            max-width: 200px;
        }
    }

    .article-date {
        text-align: center;
    }

    .news-card-info {
        color: $dark-text;
        text-align: center;
    }
}

.home-news {
    @include flex(column, flex-start, center, false);
    padding-bottom: 4rem;

    .news-container {
        display: grid;
        margin: 0 2em;
        max-width: 80%;
        grid-column-gap: 2em;

        @include device(desktop) {
            grid-row-gap: 4em;
            grid-template-columns: repeat(2, 1fr);
        }

        @include device(handheld) {
            grid-row-gap: 2em;
            grid-template-columns: 1fr;
        }
    }

    @include device(desktop) {
        .news-card {
            .img-container {
                img {
                    width: auto;
                    height: 100%;
                    max-height: 300px;
                    max-width: 40vw;
                }
            }

            &:first-child {
                font-size: 1.4em;
                grid-column: span 2;

                .img-container {
                    img {
                        width: 100%;
                        height: auto;
                        max-height: 600px;
                        max-width: 40vw;
                    }
                }
            }

            picture {
                box-shadow: -35px 35px 5px 5px rgba(0, 0, 0, 0.1);
            }

            &:hover {
                picture {
                    box-shadow: -45px 45px 5px 5px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

.news-content {
    //padding-bottom: 4rem;

    .news-container {
        display: grid;
        grid-row-gap: 2em;
        grid-column-gap: 3rem;
        position: relative;
        justify-content: center;

        @include device(desktop) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include device(tablet) {
            grid-template-columns: 1fr;
            width: 85%;
            max-width: 750px;
        }

        @include device(mobile) {
            .dated-article-card {
                padding-left: 50px;
                padding-right: 50px;
                width: auto;
            }
        }

        .news-card-small {
            max-width: 90%;
            .img-container {
                img {
                    width: auto;
                    height: 100%;
                    //max-height: 400px;
                    max-width: 100%;
                }
            }
        }
        /*&:after {
            content: "";
            height: 100%;
            position: absolute;
            left: 50%;
            width: 1px;
            background-color: $menu-black;
        }*/
    }
}

.loading-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    min-height: 500px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.page-menu-button {
    position: fixed;
    right: 0.8rem;
    bottom: 1rem;
    z-index: 10;
    font-size: 1rem;
    width: 1em;
    height: 1em;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: 4px solid $menu-black;
    background-color: $light-red;

    @include device(tablet) {
        z-index: 10;
    }
    @include device(mobile) {
        right: 2rem;
        bottom: 5rem;
        font-size: 1rem;
        z-index: 5;
    }
}

.side-panel-container {
    margin: 2em;

    .section-heading {
        font-size: 1.2em;
    }
}

.page-menu {
    transition: all 0.6s ease;

    @include device(not-mobile) {
        //position: absolute;
        // right:0;
        // top: 0;
        height: 100%;
        width: 100%;
        //display: flex;
    }

    @include device(mobile) {
        width: auto;
        padding: 0;
        min-width: 0;
        width: 100vw;
        bottom: 3.5rem;
        position: fixed;
        height: 80vh;
        display: flex;
        flex-direction: column;

        &.closed {
            transform: translateY(100%);
        }

        &.open {
            transform: translateY(0%);
            z-index: 1;
        }
    }

    ul {
        display: flex;
        list-style: none;
        padding: 0;

        @include device(not-mobile) {
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin: 0;
            margin-right: 3em;
        }

        @include device(mobile) {
            height: 100%;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0;
            flex-direction: column;
            height: 55vh;
            overflow-y: auto;
        }

        li {
            text-align: right;

            @include device(not-mobile) {
                margin-bottom: 1em;
            }

            @include device(mobile) {
                margin-bottom: 1em;
            }

            a {
                text-decoration: none;
                font-weight: 500;

                @include device(mobile) {
                    font-size: 1em;
                }
            }
        }
    }
}

.om-pages {
    display: flex;
    flex-direction: column;
    //margin: 2em 0;
    ul {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .sub-menu-item {
        font-size: 1.6em;
        text-align: center;
        text-transform: uppercase;
    }
}

.about-content {
    padding: 0;
    position: relative;
    background-color: $light-grey;

    @include device(not-mobile) {
        width: calc(100vw - 5.5rem);
    }

    .page-heading-container {
        margin-bottom: 0;
    }
    .page-menu {
    }

    .container {
        height: 100%;
        width: 100%;
        max-width: unset;
        margin-right: 11rem;
        align-self: unset;
        background-color: lighten($turq, 25%);
    }

    .about-left {
        /*padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
*/
        @include device(not-mobile) {
            margin: 0;
        }

        @include device(not-mobile) {
            margin: 0 auto;
            width: 85%;
            padding: 0 5%;
        }
        @include device(mobile) {
            width: 100%;
        }
        article {
            max-width: 800px;
        }
    }
}

.team-section {
    .container {
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: repeat(3, 1fr);
    }
}

.team-member-card {
    .team-member-image-container {
        width: 100%;
        height: 350px;
        overflow: hidden;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }

    img {
        width: 100%;
        flex: 0 0 auto;
    }
}
.story-background {
    position: fixed;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:after {
        content: "";
        background-color: rgba(0, 0, 0, 0);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        transition: background 0.3s ease;
    }
}

.story-overlay {
    &:after {
        background-color: rgba(0, 0, 0, 0.7);
    }
}
.story-article {
    display: block;
    position: relative;
    background-color: transparent;
    // top: 0;
    // left: 0;
    // height: 100vh;
    // background-size: cover;
    // overflow-y: scroll;
    // overflow-x: hidden;
    // scroll-behavior: smooth;
    // z-index: 1;

    @include device(not-mobile) {
        width: calc(100vw - 5.6rem);
        //padding-right: 5.6rem;
    }

    @include device(mobile) {
        font-size: 0.7em;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        overflow-wrap: break-word;
    }

    h1 {
        @include device(desktop) {
            font-size: 55px;
            margin: 1rem 0;
        }
    }
    a {
        color: $red-text;
    }

    .page-menu-button {
        @include device(not-mobile) {
            z-index: 10;
        }
        @include device(mobile) {
            z-index: 9;
        }
    }

    section {
        @include device(not-mobile) {
            min-height: 100vh;
        }
        @include device(tablet) {
            width: calc(100vw - 5.6rem);
        }
        @include device(handheld) {
            min-height: 80vh;
        }
    }

    .section-heading {
        text-align: left;
        color: $grey-text;
        margin-top: 5rem;
        font-weight: bold;
        @include device(handheld) {
            max-width: 85%;
            margin: 0 auto;
        }
    }
    .story-content-container {
        // transition: all .6s ease;
        display: flex;
        flex-direction: column;
        // @include device(desktop){
        //     padding-left: 16vw;
        // }
        transition: padding 0.3s ease;
        &.open {
            @include device(desktop) {
                padding-left: 16vw;
            }
        }
    }

    .article-content {
        align-self: center;
        font-family: nyt-imperial, georgia, "times new roman", times, serif;
        width: 100%;
        max-width: 1000px;
        //margin-left: calc(214px + 1em);
        color: $light-text;
        @include device(desktop) {
            max-width: 800px;
        }

        // @include device(tablet){
        //     max-width: calc(80vw - 201px - 5.6rem);
        //     align-items: center;
        //     padding-left: calc(201px + 2.8rem - 5vw);
        // }
        p {
            max-width: 650px;
            color: $light-text;
            font-size: 1.5em;
            @include device(mobile) {
                max-width: 90%;
                align-self: center;
            }
            @include device(tablet) {
                max-width: 85%;
                align-self: center;
            }
        }

        img {
            max-width: 100%;
        }

        h2 {
            color: white;
            max-width: 650px;
            font-size: 2.2em;
            text-transform: uppercase;
            @include device(mobile) {
                margin-left: 5%;
                margin-right: 5%;
            }

            @include device(tablet) {
                max-width: 85%;
                align-self: center;
            }
        }
    }

    .story-heading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        //.without-image{
        background-color: #453932;
        //}
        min-height: 100vh;

        @include device(desktop) {
            width: calc(100vw - 5.6rem);
        }
        @include device(tablet) {
            width: calc(100vw - 5.6rem);
        }
        @include device(mobile) {
            width: 100vw;
        }

        .story-heading {
            color: white;
            top: 50%;
            @include device(not-mobile) {
                margin: 20% 0;
            }
            @include device(mobile) {
                padding: 0 3rem;
            }

            h1 {
                margin: 0;
                font-size: 12em;
                line-height: 1em;
                max-width: 80vw;

                @include device(handheld) {
                    margin: 0 auto;
                    font-size: 7em;
                }
            }
            h2 {
                margin: 0;
                color: $light-red;
                font-size: 4em;
                @include device(mobile) {
                    overflow-wrap: anywhere;
                    font-size: 3em;
                }
                @include device(handheld) {
                    margin: 0 auto;
                    max-width: 80vw;
                }
            }
        }

        .story-info {
            z-index: 10;
            font-size: 1.5em;
            //min-height: 300px;
            width: 100%;
            text-align: center;
            position: relative;
            max-width: 60vw;
            padding: 25vh 0;

            h2 {
                margin: 0;
                font-family: nyt-imperial, georgia, "times new roman", times, serif;
            }
        }
    }

    .text-with-image {
        .section-heading {
            width: 100%;
            text-align: -webkit-auto;
            max-width: 1000px;
        }
        &.top {
            color: $light-text;
            align-self: center;
            font-family: nyt-imperial, georgia, "times new roman", times, serif;
            width: 100%;
            p {
            }

            .rich-text {
                font-size: 1.5em;
            }
        }
    }

    blockquote {
        color: $light-red;

        @include device(mobile) {
            max-width: 90%;
            align-self: center;
        }

        p {
            color: $light-red;
        }
    }
}

.small-quote-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-text;
    justify-content: center;
    font-size: 2em;
    color: $light-red;
}

blockquote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Reenie Beanie", cursive;
    font-size: 2em;
    align-self: flex-start;
    max-width: 400px;
    @include device(mobile) {
        margin: 0;
    }
    p {
        max-width: 80%;
        text-align: center;
        font-family: "Reenie Beanie", cursive;
        @include device(mobile) {
            max-width: 100%;
        }
    }

    &::before {
        content: "\201C";
        font-family: "Reenie Beanie", cursive;
        font-size: 5em;
        text-align: center;
        width: 100%;
        margin-top: 2rem;
        font-weight: normal;
        line-height: 0.25em;
        stroke: $light-red;
    }
}

.quote-section {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
    }

    div {
        margin-top: 4rem;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include device(mobile) {
            max-width: 90%;
        }

        @include device(tablet) {
            max-width: 85%;
            align-items: center;

            text-align: center;
        }
    }

    p {
        background-color: white;
        font-size: 2em;
        margin: 0;
        padding: 0 0.35em;
        margin-bottom: 0.25em;
        width: auto;
        display: inline-block;
        color: black;
    }
}

.content-index {
    position: fixed;
    // align-self: center;
    font-size: 1em;
    left: 0;
    padding: 0.5em;
    top: 0;
    transition: all 0.6s ease;
    background-color: $very-dark-brown;
    color: $light-text;
    text-align: right;
    height: 100vh;
    width: 16vw;
    @include device(mobile) {
        width: 100vw;
        height: 60vh;
        bottom: 3.5rem;
        left: 0;
        top: unset;
    }

    @include device(tablet) {
        width: 12rem;
    }

    @include device(small-laptop) {
        width: 12rem;
    }

    h3 {
        cursor: auto;
        text-align: right;
        @include device(mobile) {
            text-align: left;
        }
    }

    &:after {
        content: "";
        height: 90%;
        width: 1px;
        background-color: #464545;
        position: absolute;
        right: 0.5em;
        top: 5%;
        transform: scaleY(0);
        transition: transform 0.3s ease;
    }

    ul {
        list-style: none;
        padding: 0;
        font-family: brandon-grotesque, sans-serif;
        text-align: center;
        /*border: 2px solid;*/
        width: 90%;
        min-width: 12ch;
        margin: 0 auto;
        padding-top: 0;
        text-align: right;
        overflow: auto;
        max-height: 94vh;

        @include device(mobile) {
            text-align: left;
            width: 85%;
            margin: 0 auto;
            height: 96%;
        }
        li {
            font-family: brandon-grotesque, sans-serif;
            cursor: pointer;
            margin-bottom: 0.75rem;
            color: $light-text;
            @include device(mobile) {
                font-size: 1.5em;
            }
            @include device(small-laptop) {
                font-size: 0.8em;
            }
            &.active {
                color: $red-text;

                a {
                    color: $red-text;
                }
            }

            &:hover {
                font-weight: bold;
            }
        }
    }

    &.closed {
        opacity: 0;
        @include device(not-mobile) {
            transform: translate(-100%, 0%);
        }
        @include device(mobile) {
            transform: translate(0%, 100%);
        }
    }

    &.open {
        opacity: 1;
        z-index: 9;

        @include device(not-mobile) {
            transform: translate(0%, 0%);
        }
        @include device(mobile) {
            transform: translate(0%, 0%);
        }

        &:after {
            transform: scaleY(1);
            transition-delay: 0.6s;
        }
    }

    a {
        color: $light-text;
        text-decoration: none;
    }
}

.outro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 600px;

    div {
        max-width: 1000px;

        @include device(mobile) {
            max-width: 90%;
        }

        @include device(tablet) {
            max-width: 85%;
            align-self: center;
        }

        h1 {
            font-size: 10em;
            color: white;
            margin-bottom: 2rem;
            @include device(mobile) {
                font-size: 8em;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            font-size: 2.5em;
            margin: 0;
            color: $light-red;
            font-weight: bold;

            li {
                margin: 0;
                line-height: 1em;
                text-transform: uppercase;
                overflow-wrap: break-word;
            }
        }
    }

    p {
        @include device(mobile) {
            font-size: 2.5em;
        }
    }
}
.slider {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 600px;
    .slides-container {
        position: relative;
        width: 100%;
        min-height: 600px;

        @include device(desktop) {
            width: 80%;
        }

        @include device(handheld) {
            width: 100%;
        }
    }

    .slide {
        width: 100%;
        transition: left 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slider-footer-controls {
        position: absolute;
        bottom: 1rem;
        display: flex;
        justify-content: space-between;

        .slider-dot {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border-width: 1px;
            border-style: solid;
            margin: 1em;
            cursor: pointer;

            &.active {
            }
        }
    }

    .slider-side-controls {
        z-index: 1;
        div {
            position: absolute;
            font-size: 6em;
            padding: 1rem;
            top: calc(50% - 4rem);
            opacity: 0.5;
            cursor: pointer;
            @include device(mobile) {
                font-size: 2em;
                top: calc(100% - 5rem);
            }

            &:hover {
                transform: scale(1.1);
                opacity: 0.9;
            }

            &:first-child {
                left: 5rem;
                @include device(mobile) {
                    left: 1rem;
                }
            }

            &:last-child {
                right: 5rem;
                @include device(mobile) {
                    right: 1rem;
                }
            }
        }
    }

    .card-image-container {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
}

.stories-slider {
    @include device(desktop) {
        width: 80vw;
        min-height: 700px;
        max-width: 1000px;
    }

    .slides-container {
        width: 100%;
        margin: 5rem 0;
        display: flex;
        align-items: center;
    }
    &.slider {
        overflow: visible;
    }

    .story-info {
        z-index: 1;
        color: $light-text;

        q {
            font-size: 28px;
        }
    }

    .slide {
        .story-card-horizontal {
            transition: transform 0.3s ease;
            transition-delay: 0.3s;

            @include device(handheld) {
                grid-row-gap: 1em;
            }

            @include device(mobile) {
                max-width: 90%;
            }
        }

        .card-image-container {
            transition: all 0.3s ease;
            height: 28vw;

            @include device(handheld) {
                width: 100%;
            }
        }

        @include device(mobile) {
            &:nth-child(2n -1) {
                .quote-background {
                    bottom: 0;
                }
            }
            &:nth-child(2n) {
                .quote-background {
                    top: 0;
                }
            }
        }

        &:hover {
            /*  .story-card-horizontal {
                    transform: scale(1.05);
                    text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
                    transition-delay: 0s;
                }

                .card-image-container {
                    transition-delay: .3s;
                    box-shadow: 11px 10px 5px 1px rgba(0, 0, 0, .75);
                } */
        }

        .quote-background {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: auto;

            @include device(not-mobile) {
                // top: -50%;
            }
            @include device(mobile) {
                height: 90vw;
            }
            path {
                // stroke: rgba($dark-brown-svg, 0.33);
                fill: rgba($dark-brown-svg, 0.33);
            }
        }
    }
    .slider-side-controls {
        div {
            color: $light-text;
        }
    }

    .slider-footer-controls {
        .slider-dot {
            border: 1px solid $light-text;

            &.active {
                background-color: $light-text;
            }
        }
    }
}

.quote-background {
    position: absolute;

    @include device(desktop) {
        top: -30%;
        height: 165%;
    }

    @include device(handheld) {
        bottom: 30%;
        height: 100px;
    }
}

.image-gallery {
    .image-thumbs {
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-gap: 1rem;
        align-items: center;
        height: 100%;
    }

    .overlay {
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);

        .image-container {
            img {
                max-width: 90vw;
                max-height: 70vh;
                width: auto;
                height: 100%;
            }
        }
    }
}

.lightbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .loading-div {
        position: absolute;
    }

    .image-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .lightbox-thumbs {
        margin-bottom: 2rem;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        .image-thumbs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 1em;
            }
        }
    }
}

.search-panel {
    .section-heading {
        margin-bottom: 2rem;
    }

    .search {
        direction: ltr;
        margin-top: 2rem;
    }

    .search-input {
        display: flex;
        justify-content: center;

        input {
            direction: ltr;
            font-size: 0.8em;
            padding: 0.25em;
            border: none;
            font-size: 1.5em;
            padding: 0.5em;
        }
    }

    .search-results-container {
        margin-top: 2rem;
        direction: ltr;
        display: grid;
        grid-row-gap: 1rem;
        list-style: none;
        padding: 0;

        .sub-menu-item {
            text-decoration: none;
            color: black;
            font-size: 1.5em;
            text-align: center;
        }
    }
}

.text-with-image {
    display: grid;
    grid-gap: 2rem;
    position: relative;

    @include device(desktop) {
        grid-template-columns: repeat(2, 50%);
    }

    @include device(handheld) {
        grid-template-columns: 1fr;
    }

    img {
        max-width: 100%;
    }

    .text-image-intro,
    .text-image-body {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .text-image-intro {
        max-width: 60%;
        @include device(handheld) {
            max-width: 85%;
            align-self: center;
        }
        h1 {
            margin-bottom: 0;
            @include device(mobile) {
                margin: 0 5%;
                overflow-wrap: break-word;
            }
        }

        i {
            @include device(mobile) {
                margin: 0 5%;
            }
        }
        blockquote {
            margin-bottom: 6rem;
            width: fit-content;
            align-self: flex-start;
            @include device(mobile) {
                align-self: center;
            }
            p {
                max-width: 400px;
                color: white;
            }
        }
    }

    .rich-text-background {
        position: absolute;
        bottom: 0;
        width: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
        @include device(handheld) {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 120px));
        }
    }

    &.background {
        width: 100%;
        max-width: 100vw;
        grid-template-columns: 1fr;
        display: flex;
        justify-content: center;
        align-items: center;

        .image-container {
            width: 100%;
            height: 1200px;
            clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 75%);
        }

        .rich-text {
            position: absolute;
            color: white;
            font-size: 2em;
            bottom: 0;
            @include device(desktop) {
                width: 45%;
            }

            @include device(mobile) {
                width: 90%;
            }

            text-align: right;

            em {
                font-style: italic;
                margin-right: 0;

                :before {
                    content: open-quote;
                }

                :after {
                    content: close-quote;
                }
            }
        }
    }

    &.top {
        margin-top: 4rem;
        width: 100%;
        max-width: 100vw;
        grid-template-columns: 1fr;
        display: flex;
        justify-content: center;
        align-items: center;

        .image-container {
            max-width: 65%;
        }

        .rich-text {
            @include device(mobile) {
                width: 90%;
            }
        }
    }

    .text-image-content {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        z-index: 1;
        .rich-text {
            max-width: 85%;

            @include device(tablet) {
                max-width: 85%;
                align-self: center;
            }

            @include device(mobile) {
                position: relative;
                font-size: 14px;
            }
        }
        blockquote {
            align-self: center;
            max-width: 100%;
        }
    }

    .image-container {
        height: 600px;
        width: 100%;
        background-repeat: no-repeat;
        align-self: center;
        background-size: cover;
    }
}

.dynamic-content-image {
    width: 100%;
    height: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    img {
        width: 100%;
        height: auto;
    }
    .image-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: $dark-green;
        color: white;
        @include device(mobile) {
            flex-direction: column;
        }
        p {
            padding: 0.5em;
            margin: 0;
            font-weight: bold;
            &:last-child {
                justify-self: flex-end;
            }
        }
    }
}

.stories-page {
    background-color: $brown;
    .slider-section {
        background-color: $dark-brown;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .page-intro {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .page-heading-container {
        h1 {
            color: $light-text;
        }
    }

    .page-menu {
        background-color: $light-brown;
        color: $light-text;

        .sub-menu-item {
            a {
                &::before {
                    background-color: $light-text;
                }
                &:after {
                    background-color: $light-text;
                }
            }
        }
    }
}

.stories-content {
    background-color: $brown;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;

    .section-heading {
        h2 {
            color: $light-text;
        }
    }

    .stories-container {
        max-width: 1200px;
        padding: 2rem;
        @include device(desktop) {
            grid-template-columns: 1fr 1fr;
        }

        @include device(handheld) {
            grid-template-columns: 1fr;
        }

        .card-image-container {
            width: 100%;
            height: 292px;
        }
    }

    .story-intro {
        font-size: 18px;
    }
}

.stories-container {
    display: grid;
    grid-gap: 2rem;

    .story-card-horizontal {
        @include device(tablet) {
            grid-template-columns: 30vw 1fr;
        }
    }

    .story-card-small {
        position: relative;
        text-decoration: none;
        overflow: hidden;

        .card-image-container {
            width: 100%;
            background-size: cover;
            transform: scale(1);
            height: 175px;
            transition: all 3s ease;
        }

        .story-title {
            font-size: 1.5em;
            width: 100%;
            position: relative;
            bottom: 0;
            text-decoration: none;
            color: $red-text;
            text-align: left;
            color: $light-text;
            margin-top: 0.5em;
            transition: all 1s;
            transition-delay: 0s;

            span {
                text-align: center;
                font-family: "Reenie Beanie", cursive;
                margin: 0 1em;
            }
        }

        &:hover {
            .card-image-container {
                width: 100%;
                height: 175px;
                transform: scale(1.5);
            }

            .story-title {
                transition-delay: 1s;
                background-color: rgba($dark-brown, 0.7);
            }
        }
    }
}
.projects-content {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
}

.projects-container {
    width: 80vw;
    max-width: 1000px;
    display: grid;
    grid-gap: 3rem;

    @include device(desktop) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include device(handheld) {
        grid-template-columns: 1fr;
    }

    .project-card {
        text-decoration: none;
        color: inherit;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.projects-page {
    .page-menu {
        background-color: darken($purple-background, 10%);
    }

    .image-date-container {
        box-shadow: 0px 0px 0px 6px darken($purple-background, 20%);
    }
    .article-date {
        background-color: darken($purple-background, 15%);
        color: $dark-text;
        .divider {
            border-top-color: $dark-text;
        }
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}

.category-buttons {
    margin-top: 2em;
}

.category-button {
    border-radius: 20px;
    padding: 0.25em 1em;
    background-color: black;
    color: $beige;
    margin-right: 1em;
    z-index: 10;
    border: none;
    font-size: 14px;
}

.contact-page {
    background-color: $light-pink-background;
    min-height: 100vh;

    .map {
        box-shadow: 0 0 16px 4px rgba(black, 0.22);
        display: flex;
        flex-direction: column;
        @include device(handheld) {
            max-width: 90vw;
            margin-top: 6rem;
        }

        @include device(mobile) {
            order: 2;
        }

        iframe {
            display: flex;
            @include device(handheld) {
                max-width: 100%;
            }
        }
        .arkivet-logo {
            display: flex;
            flex: 1;
            background-color: $form-pink;
            justify-content: center;
            align-items: center;
            @include device(handheld) {
                padding: 2rem 0;
            }
        }
    }
    address {
        display: flex;
        align-items: center;
        font-style: normal;
        font-size: 1.3em;
        font-weight: bold;
        color: $dark-text;
        svg {
            width: 2em;
        }

        .address-lines {
            list-style: none;
        }
    }

    form {
        display: grid;
        grid-row-gap: 1em;
        height: 100%;
        position: relative;
        @include device(handheld) {
            max-width: 90vw;
            align-self: center;
            justify-self: center;
            margin-top: 2rem;
        }
        .loading-div {
            position: absolute;
            background-color: rgba($light-pink-background, 0.7);
        }
        .errors {
            padding: 0 1em;
        }
        .input-with-icon {
            display: flex;
            align-items: center;
            width: stretch;
            position: relative;
            transition: margin 0.3s ease;
            &.error {
                margin-bottom: 1em;
            }
            svg {
                position: absolute;
                right: 1em;
            }

            &.text-area {
                svg {
                    top: 1em;
                }
            }

            p.error {
                position: absolute;
                bottom: -2.5em;
                color: red;
                left: 0.5em;
            }
        }
        input {
            font-size: 1.5em;
            padding: 0.25em 0.5em;
            line-height: 1em;
            flex: 1 1 auto;
            background-color: $form-pink;
            color: $dark-text;
            font-weight: bold;
            border: none;
            outline-color: $pink-background;
            box-shadow: 0 0 16px 4px rgba(black, 0.22);
        }

        textarea {
            font-size: 1.5em;
            padding: 0.25em 0.5em;
            font-weight: bold;
            color: $dark-text;
            border: none;
            flex: 1 1 auto;
            background-color: $form-pink;
            box-shadow: 0 0 16px 4px rgba(black, 0.22);
            min-height: 150px;
            &:focus {
                outline-color: $pink-background;
            }
        }

        button {
            font-size: 2.5em;
            font-weight: bold;
            color: $light-red;
            margin: 0;
            background-color: transparent;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transform: translateX(0px) translateY(0px);
            border-bottom: 5px solid $light-red;

            svg {
                transition: all 3s;
            }

            &.ready {
                &:hover {
                    .plane {
                        animation: shake 0.5s;
                        animation-iteration-count: infinite;
                    }
                }
            }

            &.sending {
                outline: none;

                .plane {
                    animation: send 1s;
                    animation-iteration-count: 1;
                }
            }

            &:focus {
                outline: none;
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .form-container {
        display: grid;
        grid-column-gap: 2em;
        margin-bottom: 4rem;
        @include device(desktop) {
            grid-template-columns: 450px 1fr;
        }

        @include device(handheld) {
            grid-template-columns: 1fr;
        }
    }
}

.highlighted-text {
    padding: 0 2em;
    margin: 4rem 0;
    margin-bottom: 5rem;
    padding-left: 4em;
    position: relative;
    &::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: $light-green;
        width: 2em;
        left: 0;
    }
    h3 {
        position: absolute;
        top: -1em;
        left: -4em;
    }
    p {
        margin: 0;
        margin-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.boxed-text {
    padding: 2em;
    background-color: white;
    position: relative;
    margin: 2rem 0;
    ul {
        list-style: dashed;
    }

    h3 {
        position: absolute;

        @include device(not-mobile) {
            top: -1.75em;
            left: -1em;
        }
        @include device(mobile) {
            top: -1.75em;
            left: 0em;
        }

        text-transform: uppercase;
    }
    p {
        margin: 0;
        margin-bottom: 1em;
    }
    a {
        overflow-wrap: break-word;
    }
}

.page-intro-text {
    padding: 2em;
    margin-bottom: 4rem;
    position: relative;
    h2 {
        color: $light-red;
        margin: 0;
    }

    &.normal {
        font-size: 1.5em;
        padding: 2em 0;
        margin-bottom: 2rem;
        @include device(not-mobile) {
            max-width: 75%;
        }

        img {
            max-width: 100%;
        }
    }

    &.highlighted {
        border-radius: 6px;
        border: 6px solid $dark-green;
        .arrow {
            position: absolute;
            top: -0.69em;
            left: 3rem;
            font-size: 5em;
            color: $dark-green;
        }
    }
}

.large-quote-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .dq-background {
        position: absolute;
        @include device(handheld) {
            max-width: 95vw;
        }
        path {
            stroke: inherit;
        }
    }
    .large-quote-background {
        width: 100%;
        height: 1200px;
        clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 75%);
        @include device(mobile) {
            height: 600px;
        }
    }

    .rich-text {
        position: absolute;
        font-size: 3em;
        font-weight: bold;
        //z-index: 100;
        max-width: 85%;
        text-align: right;
        @include device(mobile) {
            max-width: 90%;
            font-size: 2em;
        }
    }
}

.event-page {
    background-color: $green-background;
    .dynamic-content-image {
        box-shadow: 0px 0px 0px 12px #ff3131;
    }

    .slider {
        @include device(handheld) {
            height: 700px;
            max-width: 85%;
            margin: 0 auto;
            .slider-side-controls {
                div {
                    font-size: 2em;
                }
            }

            .event-card-box {
                grid-template-columns: 1fr;
                .img-container {
                    img {
                        position: relative;
                        width: 50%;
                        transform: rotate(-15deg);
                    }
                }
            }
        }
    }
}

.event-card-box {
    border: 2px solid darken($green-background, 15%);
    background-color: lighten($green-background, 10%);
    display: grid;
    grid-template-columns: 200px 1fr;
    min-height: 400px;
    width: 750px;
    text-decoration: none;

    .img-container {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        position: absolute;
        overflow: hidden;
        height: auto;
        width: 100%;
        transform: rotate(-20deg) translateX(-25%) translateY(-15%);
        box-shadow: -35px 35px 5px 5px rgba(0, 0, 0, 0.1);

        &.no-shadow {
            box-shadow: none;
        }
    }

    .event-card-info {
        color: #11004b;
        text-decoration: none;
        padding: 0 2em;

        h2 {
            font-size: 2em;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
}

.hoz-scroll-controlls {
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4em;
    font-size: 3rem;
    height: 3rem;

    @include device(mobile) {
        bottom: 5rem;
    }
    .prev,
    .next {
        cursor: pointer;
        &:hover {
            color: $red;
        }
    }

    .prev {
        position: absolute;
        left: 0;
        top: 0;
    }

    .next {
        position: absolute;
        right: 0;
    }
}

.coming-soon-page {
    .page-heading {
        color: white;
    }

    img {
        max-width: 90vw;
    }
}

.vimeo-embed {
    display: flex;
    iframe {
        max-width: 80%;
        margin: 0 auto;
        z-index: 1;
        @include device(mobile) {
            max-height: 25vh;
        }
    }
}

.featured-video {
    iframe {
        width: 100%;
        @include device(desktop) {
            height: calc(800px * 0.56);
        }

        @include device(tablet) {
            height: calc((100vw - 5.5rem) * 0.56);
        }

        @include device(mobile) {
            height: calc(85vw * 0.56);
        }
    }
}

.view-all-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;

    @include device(mobile) {
        height: 200px;
    }
    a {
        font-size: 3em;
        font-weight: bold;
        color: inherit;
        text-decoration: none;
    }
}
