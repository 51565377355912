﻿@import 'variables';

@mixin device($media) {
    @if $media == mobile {
        @media #{$mobile} {
            @content;
        }
    }
    @else if $media == tablet {
        @media #{$tablet} {
            @content;
        }
    }
    @else if $media == desktop {
        @media #{$desktop} {
            @content;
        }
    }
    @else if $media == wide {
        @media #{$wide} {
            @content;
        }
    }
    @else if $media == handheld {
        @media #{$handheld} {
            @content;
        }
    }

    @else if $media == not-mobile {
        @media #{$not-mobile} {
            @content;
        }
    }

    @else if $media == small-laptop {
        @media #{$small-laptop} {
            @content;
        }
    }

}

@mixin flex-row-gutter($gap) {
    & {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -$gap;
        margin-right: -$gap;
    }

    & > * {
        margin: 0 $gap;
    }
}

@mixin flex-column-gutter($gap) {
    margin: -$gap 0;

    & > * {
        margin: $gap 0;
    }
}

@mixin grid-columns($columns) {
    -ms-grid-columns: $columns;
    grid-template-columns: repeat($columns, 1fr);
}

@mixin inverted-border-radius($color) {
    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 0;
        height: 50px;
        width: 25px;
        box-shadow: 0 -25px 0 0 $color;
        left: 0;
        border-top-left-radius: 25px;
    }

    &::after {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 0;
        right: 0;
        height: 50px;
        width: 25px;
        box-shadow: 0 -25px 0 0 $color;
        border-top-right-radius: 25px;
    }
}

@mixin flex($direction, $justify, $align, $wrap) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    @if $direction == column {
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    @else if $direction == row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    @if $justify == center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    @if $justify == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    @else if $justify == space-around {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    @else if $justify == space-evenly {
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }

    @else if $justify == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    @else if $justify == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    @if $align == center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @if $align == flex-start {
        -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start;
    }

    @if $align == flex-end {
        -webkit-box-align: end;
                -ms-flex-align: end;
                    align-items: flex-end;
    }

    @if $wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

%grid-gap {
    @include device(mobile) {
        grid-gap: $grid-gap-mobile;
    }

    @include device(tablet) {
        grid-gap: $grid-gap-mobile;
    }

    @include device(desktop) {
        grid-gap: $grid-gap;
    }

    @include device(wide) {
        grid-gap: $grid-gap;
    }
}

%ease-in {
    transition: all .5s ease-out;
}

%box-shadow {
    -webkit-box-shadow: 0px 0px 31px -10px rgba(184,184,184,1);
    -moz-box-shadow: 0px 0px 31px -10px rgba(184,184,184,1);
    box-shadow: 0px 0px 31px -10px rgba(184,184,184,1);
}

%absolute-top-left {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

%hide-on-handheld {
    @include device(handheld) {
        display: none;
    }
}

%hide-on-tablet {
    @include device(tablet) {
        display: none;
    }
}


%hide-on-mobile {
    @include device(mobile) {
        display: none;
    }
}

%hide-on-desktop {
    @include device(desktop) {
        display: none;
    }
}

%icon {
    width: 2em;
    height: auto;
}
