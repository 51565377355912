$mobile: "only screen and (max-width : 600px)";
$not-mobile: "only screen and (min-width : 601px)";
$tablet: "only screen and (min-width: 601px ) and (max-width : 1200px)";
$desktop: "only screen and (min-width: 1200px )";
$wide: "only screen and (min-width : 1601px)";
$handheld: "only screen and (max-width : 1200px)";
$small-laptop: "only screen and (min-width: 1000px) and (max-width: 1300px)";
$container-width-desktop: 1200px;

$menu-black: #464545;
$red: #FD5252;
$yellow-background:#DDD7CD;
$pink-background:#FF9797;
$light-pink-background: #F7E4E4;
$form-pink: #FDF0F0;
$green-background:#B2DEAC;
$purple-background: #B2A5DE;
$dark-purple: #BAB2D5;
$purple-border: #8075A8;
$black-background: #464545;
$light-purple: #E0DBF2;
$turq: #91b7b3;
$brown-black: #464545;
$dark-brown: #483B34;
$beige: #DCA984;
$light-red: #E07373;
$light-green: #BCD3C7;
$dark-brown-svg: #0A0807;
$brown: #6C594F;
$light-brown: #8D7A71;
$very-dark-brown: #1A1614;
$dark-green: #6D8377;
$red-text:#FF5252;
$dark-text: #464545;
$light-text: #DDD7CD;
$grey-text: #8B8B8B;
$light-grey: #E8EAE9;
$grid-gap: 1em;
$grid-gap-mobile: 1em;
$padding: 1em;
$margin: 1em;

$xl-font: 1.7em;
$large-font: 1.5em;
$medium-large-font: 1.2em;
$medium-small-font: .8em;
$small-font: .5em;

$border-radius: 5px;

$container-width: 100vw;
$container-width-tablet: 90vw;
$container-width-mobile: 90vw;